.section {

  .image {
    width: 100%;
    min-height: 100vh;
    background-image: url(../assets/greece.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .content {
    text-align: center;
    padding: 130px 3rem 50px;

    h3 {
      font-family: 'Reenie Beanie', cursive;
    }
    
    .profile {
      width: 120px;
      border-radius: 50%;
      text-align: center;
      margin-bottom: 1rem;
    }
    
    p {
      font-size: 14px;
      margin-bottom: 1rem;
      line-height: 1.5;
    }
  }

}
@media only screen and (min-width: 1080px) {
  .section {
    display: flex;

    .image {
      flex-grow: 1;
      flex-basis: 50%;
    }

    .content {
      flex-grow: 1;
      flex-basis: 50%;
      padding: 130px 5rem 50px;
    }
  }
}