@import url(https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

.show {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.hide {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.arrow {
  position: absolute;
  bottom: 50px;
  z-index: 100;
  color: rgb(213,178,81);

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.arrow:hover{
  color: rgb(213,178,81);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app_container__3FwrV{background-color:#fff;color:#000;line-height:1.75}.app_container__3FwrV h1,.app_container__3FwrV h2,.app_container__3FwrV h3,.app_container__3FwrV h4,.app_container__3FwrV h5,.app_container__3FwrV h6{margin:0;font-family:"Montserrat",sans-serif}.app_container__3FwrV p{margin:0}.app_container__3FwrV .app_footer__2vzCe{background-color:#00071c;color:#71798f;text-align:center;padding:4rem 0}.app_container__3FwrV .app_footer__2vzCe p{font-size:13px}.app_container__3FwrV .app_footer__2vzCe p:first-child{color:#d5b251}
.about_section__xBXxh .about_image__12TnN{width:100%;min-height:100vh;background-image:url(/static/media/greece.c243c9c8.png);background-position:center;background-repeat:no-repeat;background-size:cover}.about_section__xBXxh .about_content__2y5gm{text-align:center;padding:130px 3rem 50px}.about_section__xBXxh .about_content__2y5gm h3{font-family:"Reenie Beanie",cursive}.about_section__xBXxh .about_content__2y5gm .about_profile__2wemF{width:120px;border-radius:50%;text-align:center;margin-bottom:1rem}.about_section__xBXxh .about_content__2y5gm p{font-size:14px;margin-bottom:1rem;line-height:1.5}@media only screen and (min-width: 1080px){.about_section__xBXxh{display:flex}.about_section__xBXxh .about_image__12TnN{flex-grow:1;flex-basis:50%}.about_section__xBXxh .about_content__2y5gm{flex-grow:1;flex-basis:50%;padding:130px 5rem 50px}}
.testimonials_section__2FvkQ{min-height:100vh;padding:200px 15%;color:#000;background-image:url(/static/media/italy.3710dbc4.jpg);background-position:center;background-repeat:no-repeat;background-size:cover}.testimonials_section__2FvkQ .testimonials_content__3YGrb{text-align:center}.testimonials_section__2FvkQ .testimonials_content__3YGrb p{margin:1rem 0;font-size:14px}
.navbar_transparent__2tZ9w{opacity:0}.navbar_colored__1G0d8{height:100px;background-color:#24305e;background-color:#a8d0e6;background-color:#354c76;background-color:#5173b3;transition:opacity .5s ease-in-out;opacity:1}
.contact_section___kQ5P{color:#000}.contact_section___kQ5P h3{font-size:18px}.contact_section___kQ5P p{font-size:14px}.contact_section___kQ5P .contact_imageSm__t6lty{background-image:url(/static/media/nature.86585cb6.jpg);background-position:center;background-repeat:no-repeat;background-size:cover;width:100%;height:100vh}.contact_section___kQ5P .contact_imageLg__1Ks80{display:none}.contact_section___kQ5P .contact_content__B242p{display:flex;flex-direction:column;justify-content:space-between;padding:130px 3rem 50px;text-align:center}.contact_section___kQ5P .contact_content__B242p a{color:#5173b3}.contact_section___kQ5P .contact_content__B242p a:hover{color:rgba(81,115,179,.5)}.contact_section___kQ5P .contact_content__B242p div>a:not(:last-child){margin-right:10px}.contact_section___kQ5P .contact_content__B242p p{margin:1rem 0}@media only screen and (min-width: 1080px){.contact_section___kQ5P{display:flex}.contact_section___kQ5P .contact_imageSm__t6lty{display:none}.contact_section___kQ5P .contact_imageLg__1Ks80{display:block;background-image:url(/static/media/nature.86585cb6.jpg);background-position:center;background-repeat:no-repeat;background-size:cover;width:100%;height:80vh;flex-grow:1;flex-basis:50%}.contact_section___kQ5P .contact_content__B242p{flex-grow:1;flex-basis:50%;text-align:left;padding:130px 5rem 50px}}
