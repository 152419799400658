.section {
  min-height: 100vh;
  padding: 200px 15%;
  color: black;
  background-image: url(../assets/italy.jpg);

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  // background-size: 100%;
  background-size: cover;

  .content {
    // margin-top: 200px;
    text-align: center;

    p {
      margin: 1rem 0;
      font-size: 14px;
    }
  }
}