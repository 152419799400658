.container {
    background-color: #ffffff;
    color: #000000;
    line-height: 1.75;

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-family: 'Montserrat', sans-serif;
    }

    p {
        margin: 0;
    }

    .footer {
        background-color: #00071c;
        color: #71798f;
        text-align: center;
        padding: 4rem 0;

        p {
            font-size: 13px;
        }

        p:first-child {
            color: rgb(213,178,81);
        }
    }
}