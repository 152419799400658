@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

.show {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.hide {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.arrow {
  position: absolute;
  bottom: 50px;
  z-index: 100;
  color: rgb(213,178,81);

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.arrow:hover{
  color: rgb(213,178,81);
}