
.transparent {
  opacity: 0;
}

.colored {
  height: 100px;
  background-color: #24305E;
  background-color: #A8D0E6;
  background-color: rgba(53,76,118);
  background-color: rgb(81, 115, 179);
  // padding: 0 15%;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}