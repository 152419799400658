.section {
  color: black;

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }

  .imageSm {
    background-image: url(../assets/nature.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
  }

  .imageLg {
    display: none;
  }

  .content {
    // padding: 200px 15% 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 130px 3rem 50px;
    text-align: center;
  
    a {
      color: rgb(81, 115, 179);

      &:hover {
        color: rgba(81, 115, 179, 0.5);
      }
    }

    div > a {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    p {
      margin: 1rem 0;
    }
  }
}

@media only screen and (min-width: 1080px) {
  .section {
    display: flex;

    .imageSm {
      display: none;
    }

    .imageLg {
      display: block;
      background-image: url(../assets/nature.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 80vh;
      flex-grow: 1;
      flex-basis: 50%;
    }

    .content {
      flex-grow: 1;
      flex-basis: 50%;
      text-align: left;
      padding: 130px 5rem 50px;
    }
  }
}